/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500,700|Unbounded:200,400|Delius:200,400&display=swap');

* {
  margin: 0px;
  padding: 0px;
}

/* Fix IE's relative  font size inheritance cascade bug */
html {
  font-size: 100.01%;
  height: 100%;
}

body {
  font-family: Noto Sans KR, trebuchet ms, sans-serif;
  font-size: 14px;
  text-align: center;
  height: 100%;
  background-color: #666;
  text-align: center;
}

h1, h3 {
  font-family: Unbounded, sans-serif;
  font-weight: 200;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

a,
a:visited {
  text-decoration: none;
  color: #666;
}

p {
  margin: 16px 0px;
}

.rotate270 {
  transform: rotate(270deg);
}

.outsideTopLeft {
  position: absolute;
  left: -8.5rem;
  top: 7rem;

  a {
    font-family: Unbounded, sans-serif;
    font-size: 22px;
    color: lightgray;
  }
}

.centeredContent {
  position: relative;
  margin: 0px auto;
  max-width: 800px;
  text-align: left;
  min-height: 100%;
  background-color: white;
  border-left: 2px solid #aaa;
  border-right: 2px solid #aaa;
  padding-left: 3px;
}

.paddedContent {
  padding: 10px;
}

.title {
  font-family: Unbounded, Arial, Helvetica, sans-serif;
  font-size: 72px;
  background-color: lightgray;
  color: white;
  text-align: center;
  padding-top: 20px;
  margin-left: -3px;
  height: 100px;
  text-shadow: black 2px 2px 4px;
  // -webkit-text-stroke: 0.5px grey;
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 50px;
  }
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 40px;
  }
}

.indented {
  padding-left: 60px;
  padding-right: 60px;
}

#darkenBackground {
  background-color: rgb(0, 0, 0);
  opacity: 0.4;
  /* Safari, Opera */
  -moz-opacity: 0.40;
  /* FireFox */
  filter: alpha(opacity=40);
  /* IE */
  z-index: 20;
  height: 100%;
  width: 100%;
  background-repeat: repeat;
  position: fixed;
  top: 0px;
  left: 0px;
}

#loading {
  z-index: 100;
  position: absolute;
  top: 40%;
  left: 40%;
  font-size: 1.5em;
}

.footer {
  bottom: 0pt !important;
  position: absolute;
  width: 100%;
  padding-bottom: 10px;
}

.clickable {
  cursor: pointer;
}

.tilted {
  width: 240px;
  box-shadow: -15px 5px 50px rgba(0, 0, 0, .3);
  transform-origin: right center;
  transform: perspective(1000px) rotateY(30deg);
  -webkit-box-reflect: below -38px linear-gradient(0deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 40%);
}

.clientLogo {
  border: 0;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  padding-left: 5px;
  padding-top: 5px;
}

td.addInfo a {
  padding-right: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

td.addInfo a:hover {
  background-color: #666;
  color: white;
}

.chessPiece {
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px 5px 0px 5px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}